import type { NextComponentType } from "next";
//import {CircularProgressbar, buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
//import 'react-circular-progressbar/dist/styles.css';
import React from "react";
import { useState } from "react";
const Findings: NextComponentType = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="bg-white py-[60px] sm:py-[100px] relative bg-content">
        <h2 className="lg:text-[200px] md:text-[150px] sm:text-[100px] text-[80px] font-semibold text-[#FFF1D4] z-[0] absolute left-[50%] top-0 translate-x-[-50%] leading-none numberText">+287M</h2>
        <div className="md:w-[75%] w-full mx-auto">
          <h4 className="text-center md:text-4xl sm:text-3xl text-xl font-[500] relative z-[1] lg:leading-snug md:leading-snug">More than 287 million people, which is 37% of global total, are illiterate in India.</h4>
          <h4 className="text-center md:text-4xl sm:text-3xl text-xl font-semibold relative z-[1] lg:leading-snug md:leading-snug">And we want to change this.</h4>
          <a onClick={() => setShowModal(true)} className="hover:scale-125  transition-all block text-center mt-12 text-primary font-[500] relative z-[1] text-lg cursor-pointer">
            Watch Now <i className="fal fa-circle-play align-middle ml-2 text-3xl"></i>
          </a>
        </div>
      </div>
      {showModal ? (
        <>
          <div onClick={() => setShowModal(false)} className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative flex-auto">
                  <video
                    className="rounded-lg"
                    loop
                    controls
                    autoPlay
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <source src="Padhaai - A Million Dreams.mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* <div className='row bg-light-primary px-[30px] py-[60px] sm:px-[60px] sm:py-[100px] rounded-[40px] sm:rounded-[80px]'>
				<div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
					<h4 className='text-[28px] sm:text-4xl lg:text-5xl font-semibold xs:mb-10 mb:20 sm:text-left text-center'>
						This is what we <span className='text-primary'>found:</span>
					</h4>
					<div className='flex sm:flex-start lg:justify-between flex-wrap justify-center'>
						<div className='text-center text-[14px] sm:text-[16px] me-2 xs:mb-8 xs:px-2'>
							<div className='progress-circle'>
								<CircularProgressbarWithChildren
									background
									styles={{
										path: {
											stroke: '#f9c933',
											strokeLinecap: 'butt',
										},
										trail: {
											stroke: '#ffffff',
											strokeLinecap: 'butt',
										},
										background: {
											fill: '#ffffff',
										},
									}}
									value={39}>
									<span className='block font-semibold'>Ask a friend</span>
									<span className='block text-xl font-bold'>39%</span>
								</CircularProgressbarWithChildren>
							</div>
						</div>
						<div className='text-center text-[14px] sm:text-[16px] me-2 xs:mb-8 xs:px-2'>
							<div className='progress-circle'>
								<CircularProgressbarWithChildren
									background
									styles={{
										path: {
											stroke: '#f9c933',
											strokeLinecap: 'butt',
										},
										trail: {
											stroke: '#ffffff',
											strokeLinecap: 'butt',
										},
										background: {
											fill: '#ffffff',
										},
									}}
									value={16}>
									<span className='block font-semibold'>Search online</span>
									<span className='block text-xl font-bold'>16%</span>
								</CircularProgressbarWithChildren>
							</div>
						</div>
						<div className='text-center text-[14px] sm:text-[16px] me-2 xs:mb-8 xs:px-2'>
							<div className='progressBar'>
								<div className='progress-circle'>
									<CircularProgressbarWithChildren
										background
										styles={{
											path: {
												stroke: '#f9c933',
												strokeLinecap: 'butt',
											},
											trail: {
												stroke: '#ffffff',
												strokeLinecap: 'butt',
											},
											background: {
												fill: '#ffffff',
											},
										}}
										value={8}>
										<span className='block font-semibold'>Ask family</span>
										<span className='block text-xl font-bold'>8%</span>
									</CircularProgressbarWithChildren>
								</div>
							</div>
						</div>
						<div className='text-center text-[14px] sm:text-[16px] me-2 xs:mb-8 xs:px-2'>
							<div className='progressBar'>
								<div className='progress-circle'>
									<CircularProgressbarWithChildren
										background
										styles={{
											path: {
												stroke: '#f9c933',
												strokeLinecap: 'butt',
											},
											trail: {
												stroke: '#ffffff',
												strokeLinecap: 'butt',
											},
											background: {
												fill: '#ffffff',
											},
										}}
										value={45}>
										<span className='block font-semibold px-4 inline-bolck'>Talk to the teacher</span>
										<span className='block text-xl font-bold'>45%</span>
									</CircularProgressbarWithChildren>
								</div>
							</div>
						</div>
						<div className='text-center text-[14px] sm:text-[16px] me-2 xs:mb-8 xs:px-2'>
							<div className='progressBar'>
								<div className='progress-circle'>
									<CircularProgressbarWithChildren
										background
										styles={{
											path: {
												stroke: '#f9c933',
												strokeLinecap: 'butt',
											},
											trail: {
												stroke: '#ffffff',
												strokeLinecap: 'butt',
											},
											background: {
												fill: '#ffffff',
											},
										}}
										value={31}>
										<span className='block font-semibold'>Do nothing</span>
										<span className='block text-xl font-bold'>31%</span>
									</CircularProgressbarWithChildren>
								</div>
							</div>
						</div>
					</div>
					<div className='sm:text-left text-center mt-5 w-full block text-black py-3 pr-6 text-xl sm:text-3xl font-normal'>
						So we teamed up with thousands of tutors in the country, and we created <span className='text-primary font-semibold'>Gurucool</span>.
					</div>
				</div>
			</div> */}
    </>
  );
};

export default Findings;
