import type {NextComponentType} from 'next';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {getJwt, getUser} from '../utils/handle-session';

import Link from 'next/link';
import HighlightFreeCourse from '../images/features/highlightfreecourses.svg';
import HighlightGoals from '../images/features/highlightgoals.svg';
import HightlightLive from '../images/features/highlightlive.svg';
import HighlightMinSwatched from '../images/features/highlightminswatched.svg';
import HighlightEducators from '../images/features/hightlighteducators.svg';

const Feature: NextComponentType = () => {
	const router = useRouter();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const handleLoginWindow = () => {
		const windowRef = window.open('https://login.gurucool.xyz/signup');
		const checkWindCloseTimer = setInterval(() => {
			if (windowRef?.closed) {
				clearInterval(checkWindCloseTimer);
				router.push({
					pathname: '/connect/auth/redirect',
					query: {url: ''},
				});
			}
		}, 500);
	};

	useEffect(() => {
		if (router.isReady) {
			const user = getUser();
			const jwt = getJwt();
			if (!user || !jwt) setIsLoggedIn(false);
			else setIsLoggedIn(true);
		}
	}, [router.isReady]);

	return (
		<>
			<section className='bg-light-primary dark-mode pt-[90px] pb-[60px] sm:pt-[80px] sm:pb-[80px] lg:pt-[120px] lg:pb-[120px]'>
				<div className='innerDiv mx-auto'>
					<div className='lg:flex lg:-mx-6 items-center'>
						<div className='lg:w-1/2 lg:px-6 w-full lg:mb-4 mb-6'>
							<div className=''>
								<h2 className='text-[32px] sm:text-4xl lg:text-[48px] font-semibold lg:font-bold lg:leading-tight mb-4'>Start learning with Gurucool</h2>
								<p className='text-lg text-slate-900 font-normal mb-6'>Get unlimited access to structured courses, study materials &amp; and solved questions.</p>
								{isLoggedIn ? (
									<div className='mt-4 mb-24 hover:scale-105  transition-all inline-block'>
										<Link href='/home'>
											<a className='bg-theme rounded text-black d-inline-block px-9 py-3 font-semibold text-lg'>Explore</a>
										</Link>
									</div>
								) : (
									<button onClick={handleLoginWindow} className='inline-block px-9 py-3 mb-12 sm:mb-24 bg-theme rounded tracking-wide text-xl font-semibold'>
										Sign up
									</button>
								)}
							</div>
						</div>
						<div className='lg:w-1/2 lg:px-6 w-full'>
							<div className='sm:flex sm:-mx-6 flex-wrap'>
								<div className='sm:w-1/2 sm:px-6 w-full'>
									<div className='bg-white shadow shadow-[#000000]/[0.05] relative rounded-[10px] p-4 mb-4 overflow-hidden'>
										<div className='text-lg text-slate-600'>Cool categories</div>
										<h4 className='mt-2 text-4xl font-bold pb-20'>
											100 <span className='text-theme'>+</span>
										</h4>
										<div className='absolute -bottom-[8px] -right-[2px]'>
											<Image src={HighlightGoals} alt='Cool Categories' width={284} height={180} />
										</div>
									</div>
								</div>
								<div className='sm:w-1/2 sm:px-6 w-full'>
									<div className='bg-white shadow shadow-[#000000]/[0.05] relative rounded-[10px] p-4 mt-6 mb-4 overflow-hidden'>
										<div className='text-lg text-slate-600'>Campus</div>
										<h4 className='mt-2 text-4xl font-bold pb-20'>
											20k <span className='text-theme'>+</span>
										</h4>
										<div className='absolute -bottom-[8px] -right-[2px]'>
											<Image src={HighlightEducators} alt='Campus' width={284} height={180} />
										</div>
									</div>
								</div>
								<div className='sm:w-1/2 sm:px-6 w-full'>
									<div className='bg-white shadow shadow-[#000000]/[0.05] relative rounded-[10px] p-4 lg:-mt-4 overflow-hidden'>
										<div className='text-lg text-slate-600'>Daily live classes</div>
										<h4 className='mt-2 text-4xl font-bold pb-20'>
											20 <span className='text-theme'>+</span>
										</h4>
										<div className='absolute -bottom-[8px] -right-[2px]'>
											<Image src={HightlightLive} alt='' width={284} height={180} />
										</div>
									</div>
								</div>
								<div className='sm:w-1/2 sm:px-6 w-full mt-4'>
									<div className='bg-white shadow shadow-[#000000]/[0.05] relative rounded-[10px] p-4 overflow-hidden'>
										<div className='text-lg text-slate-600'>Video lessons</div>
										<h4 className='mt-2 text-4xl font-bold pb-20'>
											200k <span className='text-theme'>+</span>
										</h4>
										<div className='absolute -bottom-[8px] -right-[2px]'>
											<Image src={HighlightFreeCourse} alt='' width={284} height={180} />
										</div>
									</div>
								</div>

								<div className='sm:w-1/2 sm:px-6 w-full'>
									<div className='bg-white shadow shadow-[#000000]/[0.05] relative rounded-[10px] p-4 mt-4 lg:-mt-4 overflow-hidden'>
										<div className='text-lg text-slate-600'>Community</div>
										<h4 className='mt-2 text-4xl font-bold pb-20'>
											300k <span className='text-theme'>+</span>
										</h4>
										<div className='absolute -bottom-[8px] -right-[2px]'>
											<Image src={HighlightMinSwatched} alt='' width={284} height={180} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Feature;
