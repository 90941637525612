import type {NextComponentType} from 'next';
import Link from 'next/link';
import Image from 'next/image';
import GooglePlayImage from '../images/google-play.png';
// import GooglePlayImage from '../images/Download APp.png';
import DownloadImage from '../images/download.png';

const Download: NextComponentType = () => {
	return (
		<>
			<section className='slider-section pt-[60px] pb-[60px] sm:pt-[80px] sm:pb-[80px] lg:pt-[120px] lg:pb-[120px]'>
				<div className='innerDiv download mx-auto lg:pt-[120px]'>
					<div className='sm:flex lg:items-center bg-primary px-4 sm:px-[50px] sm:pt-[80px] lg:pt-[100px] rounded-[50px] justify-between sm:pb-[0] pb-[7px]'>
						<div className='sm:w-[50%] lg:w-[60%] w-full sm:px-5 sm:mb-9 sm:-mt-[10px]'>
							<h2 className='text-black leading-tight lg:text-[42px] md:text-[32px] text-3xl font-bold mb-5 pt-10 sm:pt-0'>Get the learning app</h2>
							<p className='lg:text-2xl md:text-xl text-lg mb-8'>Download lessons and learn anytime, anywhere with the Padhaai app</p>
								<a className='text-black rounded d-inline-block font-semibold text-lg inline-block pb-6 hover:scale-105 transition-all' href="https://play.google.com/store/apps/details?id=com.gcpadhaai.padhaai" target={'_blank'} rel="noreferrer">
									<Image src={GooglePlayImage} width={165} height={46} alt='Download from google play' />
								</a>
						</div>
						<div className='sm:w-[50%] lg:w-[40%] sm:px-5 w-full pt-10 sm:pt-0 sm:self-end'>
							<div className='w-full relative'>
								<div className='sm:absolute sm:bottom-[7px] right-0 w-[80%] sm:w-full xl:w-10/12 app-image '>
									<Image src={DownloadImage} height={596} width={376} alt='' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Download;
