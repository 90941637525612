import type {NextComponentType} from 'next';
import Image from 'next/image';
import NeedImage from '../images/choose-us/need.png';
import WhyChoose from '../images/choose-us/why-choose.png';
import Findings from './Findings';

const ChooseUs: NextComponentType = () => {
	return (
		<>
			<section className='chooseusSection pt-[60px] pb-[60px] sm:pt-[80px] sm:pb-[80px] lg:pt-[120px] lg:pb-[120px]'>
				<div className='innerDiv mx-auto'>
					<div className='lg:flex sm:-mx-6 mb-10'>
						<div className='lg:w-[60%] sm:px-6'>
							<div className=''>
								<h2 className='text-[32px] sm:text-4xl lg:text-5xl font-semibold mb-6 mt-10'>
									Why <div className='inline-block m-0 text-theme'>Padhaai ?</div>
								</h2>
								<p className='sm:text-2xl text-xl mb-12 lg:mb-0'>If we create a space for quality education and make it expensive, we are only excluding most of the people.</p>
							</div>
						</div>
						<div className='lg:w-[40%] sm:px-6'>
							<Image src={WhyChoose} alt='' className='img-fluid' width={461} height={293} />
						</div>
					</div>
					<div className='hidden lg:flex sm:-mx-6 justify-center mb-12'>
						<div className='lg:w-[50%] lg:pr-16 mb-5'>
							<Image src={NeedImage} width={420} height={310} alt='' className='img-fluid' />
						</div>
						<div className='lg:w-[40%] sm:px-6 text-md-start mb-5 pt-0 sm:pt-10 mt-10'>
							<p className='text-4xl lg:text-4xl font-semibold text-slate-900 mb-4'>Then we asked ourselves</p>
							<h3 className='mb-3 sm:text-2xl lg:text-2xl leading-medium font-normal	'>&lsquo;How can we ensure free, quality and hyperlocal education for all?&rsquo;</h3>
						</div>
					</div>

					<div className='block lg:hidden sm:-mx-6 justify-center mb-24'>
						<div className='lg:w-[60%] sm:px-6 text-md-start mb-5 sm:mb-20 sm:pt-10'>
							<p className='text-[32px] sm:text-4xl lg:text-5xl leading-tight font-semibold text-slate-900 mb-4'>Then we asked ourselves</p>
							<h3 className='mb-3 text-xl sm:text-2xl lg:text-2xl leading-medium font-normal	'>&lsquo;How can we ensure free, quality and hyperlocal education for all?&rsquo;</h3>
						</div>
						<div className='lg:w-[40%] sm:px-6 mb-5'>
							<Image src={NeedImage} width={460} height={340} alt='' className='img-fluid' />
						</div>
					</div>

					<Findings />
				</div>
			</section>
		</>
	);
};
export default ChooseUs;
