import type { NextComponentType } from 'next';
import Link from 'next/link';
import Image from 'next/image';

// import ImpactImage from '../images/impactabnner.png';
import ImpactImage2 from '../images/home/earth-c.png';

const Impact: NextComponentType = () => {
	return (
		<>
			<section className='slider-section'>
				<div className='innerDiv mx-auto'>
					<div className='flex flex-wrap mb-4 sm:-mx-3 sm:items-center'>
						<div className='sm:w-[50%] lg:w-[60%]  w-full px-3 lg:mb-0 mb-9 sm:order-1 order-2'>
							<h2 className='text-black text-center sm:text-left text-[36] leading-snug lg:text-[56px] text-4xl font-bold mb-9'>
								A dream
								<br />
								becomes a<br />
								million dreams.
							</h2>
							<p className='sm:text-2xl text-xl text-center sm:text-left mb-9'>Unveiling Padhaai&apos;s Impact Report.</p>
							<div className='text-center sm:text-left hover:scale-105 transition-all block w-fit'>
								<Link href='/impactreport'>
									<a className='bg-theme rounded text-black d-inline-block px-9 py-4 font-semibold text-lg inline-block'>Read our Impact Report</a>
								</Link>
							</div>
						</div>
						<div className='sm:order-2 order-1 m-auto w-[70%] sm:w-[50%] lg:w-[40%] px-3 sm:pb-12 lg:pb-0 lg:pt-8'>
							<Image src={ImpactImage2} className='w-full' alt='' />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Impact;
