import Image from 'next/image';
import Link from 'next/link';
import {useState, useEffect} from 'react';
import LearningImage from '../images/prep/learning.svg';
import PrepLearningImage from '../images/prep/prep-learning.svg';
import {strapi_end_point} from '../config';
const qs = require('qs');

const PrepareSection = () => {
	const [isPopupOpen, setPopup] = useState(true);
	const [isActive, setActive] = useState(false);
	const toggleClass = () => {
		setActive(!isActive);
	};
	const [isLoading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [subcat, SetSubcategory] = useState([]);
	const GetData = async () => {
		const query = qs.stringify(
			{
				filters: {
					id: [0, 9],
				},
				sort: ['id:asc'],
				fields: ['id', 'name', 'slug'],
				populate: {
					
					subcat_id: {
						filters: {
							status: true,
						},
						sort: ['sorting:asc'],
						fields: ['id', 'name', 'slug'],
					},
				},
				pagination: {
					pageSize: 10,
					page: 1,
				},
				publicationState: 'live',
				locale: ['en'],
			},
			{
				encodeValuesOnly: true, // prettify url
			}
		);
		const Response = await fetch(`${strapi_end_point}/api/padhaai-categories?${query}`);
		const Data = await Response.json();
		setData(Data.data);
		
		setLoading(false);
	};
	useEffect(() => {
		if (!isLoading) {
			setLoading(true);
		}

		GetData();
	}, []);
	return (
		<section className='innerDiv mx-auto pt-0'>
			{/* popUp code */}
			{/* <div className={`${isPopupOpen ? 'hidden' : ''}`}>
				<div onClick={() => setPopup(!isPopupOpen)} className='h-screen w-screen  fixed top-0 left-0 z-10 bg-black opacity-70'></div>
				<div className='py-8 sm:px-8 px-4 xl:w-1/3 md:w-9/12 w-11/12 bg-white rounded-md fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  z-20 '>
					<div className='mb-4 border-b-gray-300 border-b flex justify-between'>
						<h2 className='text-black mb-4 text-xl font-medium'>Select Language</h2>
						<i className='fa-solid fa-xmark text-2xl cursor-pointer' onClick={() => setPopup(!isPopupOpen)}></i>
					</div>
					<div className='border-b-gray-300 border-b mb-4 pb-4'>
						<h4 className='text-lg text-gray-700 font-medium pb-4'>View languages of</h4>
						<a
							onClick={(e) => {
								e.preventDefault();
							}}
							className='cursor-pointer inline-block bg-primary sm:px-9 px-6 py-2 rounded-full text-lg mr-3'>
							India
						</a>
						<a
							onClick={(e) => {
								e.preventDefault();
							}}
							className='cursor-pointer inline-block bg-gray-200 sm:px-6 px-4 py-2 rounded-full text-lg'>
							International
						</a>
					</div>
					<h4 className='text-lg text-gray-700 font-medium border-b-gray-300 border-b pb-4'>Available Languages</h4>
					<div className='table-language maxh-400 overflow-y-scroll'>
						<table className='w-full'>
							<tbody>
								<tr className={`${isActive ? 'cursor-pointer hover:bg-primary-500 bg-primary-500' : 'cursor-pointer hover:bg-primary-500'}`} onClick={toggleClass}>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>हिंदी</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(hindi)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-pointer hover:bg-primary-500'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>English</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(english)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-pointer hover:bg-primary-500'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>ಕನ್ನಡ</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(kannada)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-pointer hover:bg-primary-500'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>தமிழ்</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(tamil)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-pointer hover:bg-primary-500'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>తెలుగు</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(telugu)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-pointer hover:bg-primary-500'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>मराठी</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(marathi)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-pointer hover:bg-primary-500'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>বাংলা</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(bangla)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-pointer hover:bg-primary-500'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>অসমীয়া</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(assamese)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-pointer hover:bg-primary-500'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>ગુજરાતી</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(gujarati)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-pointer hover:bg-primary-500'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>ਪੰਜਾਬੀ</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(punjabi)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>
										<i className='fa-solid fa-circle-check md:text-2xl text-gray-400'></i>
									</td>
								</tr>
								<tr className='cursor-not-allowed opacity-50'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>ଓଡ଼ିଆ</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(oriya)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-base text-sm'>Coming Soon</td>
								</tr>
								<tr className='cursor-not-allowed opacity-50'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>नेपाली</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(nepali)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-lg text-sm'>Coming Soon</td>
								</tr>
								<tr className='cursor-not-allowed opacity-50'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>कश्मीरी</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(kashmiri)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-base text-sm'>Coming Soon</td>
								</tr>
								<tr className='cursor-not-allowed opacity-50'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>മലയാളം</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(malayalam)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-base text-sm'>Coming Soon</td>
								</tr>
								<tr className='cursor-not-allowed opacity-50'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>कोंकणी</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(konkani)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-base text-sm'>Coming Soon</td>
								</tr>
								<tr className='cursor-not-allowed opacity-50'>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>मणिपुरी</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black md:text-base text-sm'>(manipuri)</td>
									<td className='border-b border-slate-300 py-4 md:px-4 px-3 pl-2 text-black text-right md:text-base text-sm'>Coming Soon</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<p className='text-base mt-4 mb-4'>
				Padhaai offered in:{' '}
				<a onClick={() => setPopup(!isPopupOpen)} className='text-black cursor-pointer'>
					<strong>
						English <i className='fas fa-caret-down'></i>
					</strong>
				</a>
			</p> */}
			<div className='lg:flex mt-6 sm:-mx-4'>
				<div className='lg:w-1/2 px-4 w-full lg:mb-0 mb-6 '>
					<div className='overflow-hidden shadow-sm relative card bg-light-primary rounded-[40px] hover:bg-[#f9efd1] transition-all ease-in-out'>
						<div className='px-8 sm:px-16 lg:px-14 pt-20 pb-16 sm:py:20 lg:py:28'>
							<div className='font-medium text-gray-600 text-xl mb-2'>Prepare for</div>
							<h4 className='font-semibold sm:font-bold leading-tight text-black text-2xl sm:text-3xl mb-4'>Competitive exams</h4>
							<ul className='sm:mt-4 mb-sm-4 mb-5 min-h-[48px]'>
								{!isLoading &&
									data[0].attributes.subcat_id.data.map((subcategory, index) => {
										return (
											<li key={index} className='inline-block lg:mr-5 mr-6 pb-2'>
												<Link href={`/padhaai-gurucool/subcategory/${subcategory.attributes.slug}`} passHref>
													<a className='text-black font-medium sm:text-base text-sm hover:scale-105 transition-all ease-in-out'>{subcategory.attributes.name}</a>
												</Link>
											</li>
										);
									})}
							</ul>
							{!isLoading && (
								<Link href={`/home`} passHref>
									<a className='bg-theme tracking-wide sm:text-base text-sm font-semibold rounded text-black sm:px-9 px-3 sm:py-3 py-2 inline-block hover:scale-105  transition-all'>Start Learning</a>
								</Link>
							)}
							<div className='absolute bottom-0 right-0'>
								<Image className='w-1/3' src={PrepLearningImage} alt='Competitive exams' width={180} height={180} />
							</div>
						</div>
					</div>
				</div>
				<div className='lg:w-1/2 px-4 w-full'>
					<div className='overflow-hidden shadow-sm relative card bg-light-primary rounded-[40px] hover:bg-[#f9efd1] transition-all ease-in-out'>
						<div className='px-8 sm:px-16 lg:px-14 pt-20 pb-16 sm:py:20 lg:py:28'>
							<div className='font-medium text-gray-600 text-xl mb-2'>Prepare for</div>
							<h4 className='font-semibold sm:font-bold leading-tight text-black text-2xl sm:text-3xl mb-4'>Class 6 to 12</h4>
							<ul className='sm:mt-4 mb-sm-4 mb-5 min-h-[48px]'>
								{!isLoading &&
									data[1].attributes.subcat_id.data.map((subcategory, index) => {
										
										return (
											<li key={index} className='inline-block lg:mr-5 mr-6 pb-2'>
												<Link href={`/padhaai-gurucool/subcategory/${subcategory.attributes.slug}`} passHref>
													<a className='text-black font-medium sm:text-base text-sm'>{subcategory.attributes.name}</a>
												</Link>
											</li>
										);
									})}
							</ul>
							{!isLoading && (
								<Link href={`/home?tab=${data[1].id}`} passHref>
									<a className='bg-theme tracking-wide sm:text-base text-sm font-semibold rounded text-black sm:px-9 px-3 sm:py-3 py-2 inline-block hover:scale-105  transition-all'>Start Learning</a>
								</Link>
							)}
							<div className='absolute bottom-0 right-0'>
								<Image src={LearningImage} alt='Class 6 to 12' width={180} height={180} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PrepareSection;
