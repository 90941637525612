import type {NextComponentType} from 'next';
import Image from 'next/image';

import PrepareSection from '../components/PrepareSection';
import Lottie from 'lottie-react';

import animationData from '../lotties/sliderlottie.json';
import Link from 'next/link';

import heroImage from '../images/home/home.png';
import {useState} from 'react';
const Hero: NextComponentType = () => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const PauseLoading = () => {
		setLoading(!isLoading);
	};
	return (
		<>
			<section onLoad={PauseLoading} className="slider-section pt-[60px] pb-[60px] sm:pt-[80px] sm:pb-[100px] lg:pt-[100px] lg:pb-[100px]">
				<div className="innerDiv mx-auto pb-0 sm:pb-[80px] lg:pb-0 !important">
					<div className="sm:flex mb-4 sm:-mx-3 sm:items-center">
						<div className="sm:w-[50%] lg:w-[50%] w-full sm:px-3 sm:mb-0 mb-9">
							<h1 className="text-black text-[32px] sm:text-[36px] lg:text-[52px] leading-snug font-bold mb-9">
								India&apos;s Largest
								<br />
								Free Learning Platform.
							</h1>
							<Link href="/home">
								<a className="bg-theme rounded text-black inline-block px-9 py-3 font-semibold text-lg hover:scale-105 transition-all ease-in-out">Explore</a>
							</Link>
						</div>
						<div className="sm:w-[50%] lg:w-[50%] sm:px-3 w-full">
							{/* <Lottie animationData={animationData} loop autoPlay /> */}
							<Image priority={true} src={heroImage} className="w-full self-center" width="628" height="505" alt="" />
						</div>
					</div>
				</div>
				<PrepareSection />
			</section>
			{isLoading && (
				<h2 hidden>Loading</h2>
			)}
		</>
	);
};
export default Hero;
