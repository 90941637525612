import Image from 'next/image';
import Link from 'next/link';
import Icon1 from '../images/home-popup/sad-crying.svg';
import Icon2 from '../images/home-popup/sad-dizzy.svg';
import Icon3 from '../images/home-popup/sad-squint.svg';
import Icon4 from '../images/home-popup/sad-squint-1.svg';
import Icon5 from '../images/home-popup/sad-squint-2.svg';
const PageLoadModal = () => {

	return (
		<>
		<div className="bg-white rounded-[10px] md:w-[525px] w-full p-4 fixed sm:bottom-[20px] bottom-0 md:right-[20px] right-[0] md:left-auto left-[0] pageload-modal z-[99] hidden">
			<div className="flex justify-between mb-4">
			<h4 className="text-xl text-black">What is the main reason to visit Padhaai today?</h4>
				<a><i className="fa-regular fa-xmark text-xl px-4"></i></a>
			</div>
			<ul className="flex justify-between border-b border-b-[#DEDEDE] pb-4 mb-4">
				<li>
					<Image src={Icon1} width={40} height={40} alt=""/>
					<span className="text-black text-xs sm:block hidden">Very Dissatisfied</span>
				</li>
				<li>
				<Image src={Icon2} width={40} height={40} alt=""/>
				</li>
				<li>
				<Image src={Icon3} width={40} height={40} alt=""/>
				</li>
				<li>
				<Image src={Icon4} width={40} height={40} alt=""/>
				</li>
				<li className="sm:text-right">
				<Image src={Icon5} width={40} height={40} alt=""/>
				<span className="text-black text-xs sm:block hidden">Very Satisfied</span>
				</li>
			</ul>
			<p className="text-xs text-[#656565]">We will use your feedback to improve our products and tools</p>
			</div>

			<div className="bg-white rounded-[10px] md:w-[525px] w-full p-4 fixed sm:bottom-[20px] bottom-0 md:right-[20px] right-[0] md:left-auto left-[0] pageload-modal z-[99] hidden">
			<div className="flex justify-between mb-4 p-4">
			<h4 className="text-xl text-black">Overall, how satisfied or dissatisfied are you with PadhaaI?</h4>
				<a><i className="fa-regular fa-xmark text-xl"></i></a>
			</div>
			<ul className="">
				<li className="py-2 px-4">Learn some new stuff</li>
				<li className="py-2 px-4 bg-[#ECECEC]">Explore opportunities</li>
				<li className="py-2 px-4">Learn some new stuff</li>
				<li className="py-2 px-4">Explore opportunities</li>
			</ul>
			</div>
		</>
	);
};
export default PageLoadModal;
